import React from 'react';
import moment from 'moment';

import { FilterField, SortField } from '../../../../components/Table';

const getRowColor = (name, ownerEmail) => {
  if ((name === '') || (ownerEmail === '-')) {
    return 'bg-warning';
  }
  return '';
  // TODO: check if mac exists in macs
  // return () ? 'bg-danger' : '';
};

const getTimeColor = (date, otherDate) => {
  if (date === '-') {
    return (otherDate === '-') ? 'bg-danger' : '';
  }

  if (moment().diff(moment(date), 'days') > 15) {
    return 'bg-danger';
  } else {
    return (moment().diff(moment(date), 'days') > 5) ? 'bg-warning' : '';
  }
};

const getVersionColor = (deviceVersion, currentVersion) => {
  if (deviceVersion !== currentVersion) {
    return 'bg-warning';
  }
  return '';
};

const Component = ({ macForUpgrade, versions, list, total, filters, sort, handlers }) => (
  <div className="col-md-12">
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <div><h5 className="card-title">Devices (<b className="text-info">{list.length} : {total}</b>)</h5></div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-small">
            <thead className="text-primary">
              <tr>
                <SortField className="col-auto text-center" name="mac" label="mac" sort={sort} handlers={handlers} />
                <SortField className="col-auto text-left" name="type" label="Type" sort={sort} handlers={handlers} />
                <SortField className="col-1 text-left" name="name" label="Name" sort={sort} handlers={handlers} />
                <SortField className="col-1 text-left" name="ownerEmail" label="Owner" sort={sort} handlers={handlers} />
                <SortField className="col-1 text-left" name="version" label="Version" sort={sort} handlers={handlers} />
                <SortField className="col-1 text-left" name="lastSeen" label="Last seen" sort={sort} handlers={handlers} />
                <SortField className="col-1 text-left" name="lastUsed" label="Last used" sort={sort} handlers={handlers} />
                <SortField className="col-auto text-center" name="created" label="Created" sort={sort} handlers={handlers} />
              </tr>
              <tr>
                <FilterField name="mac" filters={filters} handlers={handlers} />
                <FilterField name="type" filters={filters} handlers={handlers} />
                <FilterField name="name" filters={filters} handlers={handlers} />
                <FilterField name="ownerEmail" filters={filters} handlers={handlers} />
                <FilterField name="version" filters={filters} handlers={handlers} />
                <th />
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
            {list && list.map((data, idx) => (
              <tr key={idx} className={getRowColor(data.name, data.ownerEmail)}>
                <td className="text-center">{data.mac}</td>
                <td className="text-left">{data.type}</td>
                <td className="text-left">{data.name}</td>
                <td className="text-left">{data.ownerEmail}</td>
                <td className={`text-nowrap text-right pr-2 ${getVersionColor(data.version, versions[data.type])}`}>
                  {data.version}
                  {(data.version !== versions[data.type]) && !data.forceUpgrade && (
                    <span className="ml-3 pl-2 pr-2 pb-1 btn btn-sm btn-round btn-info" onClick={handlers.onUpgrade(data.mac)}>
                      <i className="nc-icon nc-cloud-download-93"/>
                    </span>
                  )}
                  {(data.version !== versions[data.type]) && data.forceUpgrade && (
                    <span className="ml-3 pl-2 pr-2 pb-1 btn btn-sm btn-round btn-danger" onClick={handlers.onUpgrade(data.mac)}>
                      <i className="fa fa-spinner fa-pulse"/>
                    </span>
                  )}
                </td>
                <td className={`text-left text-nowrap ${getTimeColor(data.lastUsed, data.lastSeen)}`}>{data.lastUsed}</td>
                <td className={`text-left text-nowrap ${getTimeColor(data.lastSeen, data.lastUsed)}`}>{data.lastSeen}</td>
                <td className="text-center text-nowrap">{data.created}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div className={`modal ${macForUpgrade && 'show'}`} style={{display: macForUpgrade ? 'block' : 'none'}}>
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-body p-0 pt-3">
            <p className="text-center text-danger"><b>Are you sure?</b></p>
            <p className="text-center"><b>{macForUpgrade}</b></p>
          </div>
          <div className="modal-footer d-flex justify-content-between p-0 pl-2 pr-2">
            <div className="left-side">
              <button type="button" className="btn btn-danger" onClick={handlers.onUpgradeConfirm}>Upgrade</button>
            </div>
            <div className="right-side">
              <button type="button" className="btn btn-info" onClick={handlers.onUpgradeCancel}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
);

export default Component;
