import './console';
import DEFAULT_LANGUAGE from './i18n';

const config = {
  WEB_ROOT:
    `${process.env.REACT_APP_WEB_PROTOCOL}://` +
    `${process.env.REACT_APP_WEB_HOST}:` +
    `${process.env.REACT_APP_WEB_PORT}`,
  APP_ROOT:
    `${process.env.REACT_APP_APP_PROTOCOL}://` +
    `${process.env.REACT_APP_APP_HOST}:` +
    `${process.env.REACT_APP_APP_PORT}`,
  SYS_API_HTTP_ROOT:
    `${process.env.REACT_APP_SYS_API_HTTP_PROTOCOL}://` +
    `${process.env.REACT_APP_SYS_API_HTTP_HOST}:` +
    `${process.env.REACT_APP_SYS_API_HTTP_PORT}`,
  MQTT_ROOT:
    `${process.env.REACT_APP_MQTT_PROTOCOL}://` +
    `${process.env.REACT_APP_MQTT_HOST}:` +
    `${process.env.REACT_APP_MQTT_PORT}`,
  MQTT_USERNAME: `${process.env.REACT_APP_MQTT_USERNAME}`,
  MQTT_PASSWORD: `${process.env.REACT_APP_MQTT_PASSWORD}`,

  SENTRY_DSN: `${process.env.REACT_APP_SENTRY_DSN}`,

  LANG: DEFAULT_LANGUAGE,
};

export default config;
