import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import { api as endpoints, callApi } from '../api';
import { user as userModule } from '../../modules';

const AUTH_STATE_PATH = ['user'];

const initialState = {
  data: {},
  pending: false,
  success: false,
  error: null,
};

const useApiRequest = (successCallback) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const user = useSelector((store) => pathOr({}, AUTH_STATE_PATH)(store));
  
  const successFn = useCallback((result) => {
    setState({ ...initialState, data: result, success: true });
    if (successCallback instanceof Function) {
      successCallback(result);
    }
  }, [successCallback]);

  const handleError = useCallback((error, api, body) => {
    // token expired
    if (error.status === 451) {
      callApi(endpoints.auth.refreshToken(), null, { jwt: user.refresh }).then((result) => {
        if (result.error) {
          dispatch(userModule.logout.action());
        } else {
          const freshUser = result;
          dispatch(userModule.login.action(freshUser));
          callApi(api, body, freshUser).then((result) => {
            if (result.error) {
              setState({ ...initialState, error: result });
            } else {
              successFn(result);
            }
          });
        }
      });
    } else {
      setState({ ...initialState, error });
    }
  }, [user.refresh, dispatch, successFn]);

  const execute = useCallback((api, body) => {
    setState({ ...initialState, pending: true });
    callApi(api, body, user).then((result) => {
      if (result.error) {
        return handleError(result, api, body);
      }
      successFn(result);
    });
  }, [user, handleError, successFn]);

  const resetState = useCallback(() => {
    setState(initialState);
  }, []);

  return { state, resetState, execute };
};

export default useApiRequest;
